import * as React from 'react';
import { Card, Checkbox, Grid } from 'semantic-ui-react';
import AnimateHeight from 'react-animate-height';

//import "./YesNoBox.css";
import TitleTextImage from './TitleTextImage';
import Group from './Group';

interface YesNoBoxProps {
  data: any;
  update: () => void;
}

interface YesNoBoxState {
  selected: string | null;
}

export default class YesNoBox extends React.Component<
  YesNoBoxProps,
  YesNoBoxState
> {
  public constructor(props: YesNoBoxProps) {
    super(props);
    this.state = { selected: null };
  }

  public render() {
    let children, buttons;

    return (
      <div className={'container'}>
        <div
          className={
            this.state.selected === 'Yes'
              ? 'box yesNoBoxContent selected'
              : 'box yesNoBoxContent'
          }
        >
          <TitleTextImage data={this.props.data} />
        </div>
        <div
          className={
            this.state.selected === 'Yes'
              ? 'yesNoBoxButton selected yes'
              : 'yesNoBoxButton yes'
          }
          onClick={() => this.select('Yes')}
        >
          Yes
          <div
            className={
              this.state.selected === 'Yes' ? 'arrow selected yes' : 'arrow yes'
            }
          />
        </div>
        <div
          className={
            this.state.selected === 'No'
              ? 'yesNoBoxButton selected no'
              : 'yesNoBoxButton no'
          }
          onClick={() => this.select('No')}
        >
          No
          <div
            className={
              this.state.selected === 'No' ? 'arrow selected no' : 'arrow'
            }
          />
        </div>
        <AnimateHeight
          height={this.state.selected === 'Yes' ? 'auto' : '0'}
          duration={1000}
        >
          <Group data={this.props.data.options['Yes']} update={this.props.update} />
        </AnimateHeight>
        <AnimateHeight
          height={this.state.selected === 'No' ? 'auto' : '0'}
          duration={1000}
        >
          <Group data={this.props.data.options['No']} update={this.props.update} />
        </AnimateHeight>
      </div>
    );
  }

  public select(key: string): void {
    if (this.state.selected === key) {
      this.setState({ selected: null });
      this.props.data.selected = null;
    } else {
      this.setState({ selected: key });
      this.props.data.selected = key;
    }
    this.props.update();
  }
}
