import * as React from 'react';
import { Card, Checkbox, Grid } from 'semantic-ui-react';

import QuestionBox from './QuestionBox';
import Spacer from './Spacer';
import CheckBox from './CheckBox';
import YesNoBox from './YesNoBox';
//import DeclarationBox from './DeclarationBox';
//import ConclusionBox from './ConclusionBox';

interface GroupProps {
  data: any;
  update: () => void;
}

export default class Group extends React.Component<GroupProps> {
  public constructor(props: GroupProps) {
    super(props);
  }

  public render() {
    const children = [];
    if (Array.isArray(this.props.data)) {
      this.props.data.map((data: any) => {
        children.push(this.createNode(data));
      });
    } else {
      children.push(this.createNode(this.props.data));
    }

    return (
      <div className="group">
        {children}
      </div>
      );
  }

  createNode(data: any) {
    if (data.options && data.options.Agree) {
      return <CheckBox data={data} update={this.props.update} />;
    }
    if (data.options && data.options.Yes && data.options.No) {
      return <YesNoBox data={data} update={this.props.update} />;
    }
    if (data.spacer) {
      return <Spacer />;
    }
    if (data.options) {
      return <QuestionBox data={data} update={this.props.update} />;
    }
    return <QuestionBox data={data} update={this.props.update} />
  }

  addNode() {
    alert("add");
  }

  removeNode(obj) {
    alert("rm");

  }
}
