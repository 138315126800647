import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';

import { Main } from './Main';

let treeData =
[
  {
    "title": "I support my friends & family who are LGBT+",
    "image": "pride.png",
    "options": {
      "Agree": [
        {
          "title": "I'm voting Democratic because",
          "text": "Republican politicians have consistently tried to strip my friends and families right to marry and protections from discrimination.",
          "cite": [
            "https://votesmart.org/interest-group/1256/rating/9684#.W8vNkWhKjIU"
          ]
        }
      ]
    }
  },
  { "spacer": 1 },
  {
    "title": "I support my friends & family who are women",
    "image": "women.png",
    "options": {
      "Agree": [
        {
          "title": "I'm voting Democratic",
          "text": "because the government has no right to invade womens privacy and make medical decisions for them.",
          "cite": [
            "https://votesmart.org/interest-group/1578/rating/10253#.W8vNQWhKjIU"
          ]
        },
        {
          "title": "I'm voting Democratic",
          "text": "because Republican politicians consistently don't believe rape victims.",
          "cite": [
            "https://www.vox.com/policy-and-politics/2018/10/3/17932324/brett-kavanaugh-news-republicans-poll-sexual-assault",
            "https://www.newsweek.com/me-too-movement-far-republicans-66-percent-say-poll-1164693"
          ]
        }
      ]
    }
  },
  { "spacer": 1 },
  {
    "title": "I support my friends & family who are black",
    "image": "blm.png",
    "options": {
      "Agree": [
        {
          "title": "I'm voting Democratic",
          "text": "because Republican politicians consistently fight my friends and families protesting for equal rights and justice.",
          "cite": [
            "http://www.pewresearch.org/fact-tank/2016/07/08/how-americans-view-the-black-lives-matter-movement/"
          ]
        }
      ]
    }
  },
  { "spacer": 1 },
  {
    "title": "I support my friends & family who are Muslim",
    "image": "muslim.png",
    "options": {
      "Agree": [
        {
          "title": "I'm voting Democratic",
          "text": "so my Muslim friends families are not banned from the US.",
          "cite": [
            "https://en.wikipedia.org/wiki/Executive_Order_13769",
            "https://www.npr.org/2018/06/26/606481548/supreme-court-upholds-trump-travel-ban"
          ]
        },
        {
          "title": "I'm voting Democratic",
          "text": "to stop Republican politicians from stoking fear of my friends and family.",
          "cite": [
            "https://medium.com/nilc/86-times-donald-trump-displayed-or-promoted-islamophobia-49e67584ac10",
            "https://www.bbc.com/news/world-us-canada-42166663"
          ]
        }
      ]
    }
  },
  { "spacer": 1 },
  {
    "title": "I support my friends & family who are senior citizens",
    "image": "middleclass.png",
    "options": {
      "Agree": [
        {
          "title": "I'm voting Democratic",
          "text": "so Republican politicians don't strip away social security from my older friends and family.",
          "cite": [
            "https://votesmart.org/interest-group/959/rating/10509#.W8vODGhKjIU",
            "https://www.cbo.gov/system/files/115th-congress-2017-2018/costestimate/53126-health.pdf"
          ]
        }
      ]
    }
  },
  { "spacer": 1 },
  {
    "title": "I support my friends and family who are struggling with poverty",
    "image": "poverty.png",
    "options": {
      "Agree": [
        {
          "title": "I'm voting Democratic",
          "text": "so Republican politicians stop stripping social safety nets for my friends and family to pay for their corporate tax cuts.",
          "cite": [
            "https://www.cbpp.org/research/food-assistance/presidents-budget-would-cut-food-assistance-for-millions-and-radically",
            "https://www.businessinsider.com/gop-food-stamp-cut-39-billion-2013-9"
          ]
        }
      ]
    }
  },
  { "spacer": 1 },
  {
    "title": "I support my friends and family with pre-existing health conditions",

    "options": {
      "Agree": [
        {
          "title": "I'm voting Democratic",
          "text": "so Republican politicians stop voting to remove affordable coverage for my friends with pre-existing conditions.",
          "cite": [
            "https://www.cbpp.org/research/health/latest-republican-aca-repeal-plan-would-have-similar-harmful-impacts-on-coverage-and"
          ]
        }
      ]
    }
  },
  { "spacer": 1 },
  {
    "title": "I support my friends and families' kids",
    "image": "kids.png",
    "options": {
      "Agree": [
        {
          "title": "I'm voting Democratic",
          "text": "so Republican politicians stop defunding SNAP programs to feed hungry kids.",
          "cite": "https://www.cbpp.org/research/food-assistance/presidents-budget-would-cut-food-assistance-for-millions-and-radically"
        },
        {
          "title": "I'm voting Democratic",
          "text": "so Republican politicians stop defunding public schools",
          "cite": [
            "https://www.usnews.com/opinion/articles/2017-04-18/donald-trump-and-betsy-devos-budget-destroys-public-education-for-kids",
            "https://votesmart.org/interest-group/1015/rating/10343#.W8vLhWhKjIU"
          ]
        },
        {
          "title": "I'm voting Democratic",
          "text": "so Republican politicians stop defunding CHIP to help sick kids.",
          "cite": "https://www.cnn.com/2018/05/08/politics/white-house-chip-funding/index.html"
        }
      ]
    }
  }
]
;

ReactDOM.render(<Main treeData={treeData}/>, document.getElementById('example'));
