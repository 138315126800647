import * as React from 'react';
import { Card, Checkbox, Grid } from 'semantic-ui-react';

import './Header.css';

export default class HEader extends React.Component {
  public render() {
    return <div className={'header'}></div>;
  }
}
