import * as React from 'react';
import { Card, Checkbox, Grid } from 'semantic-ui-react';
import AnimateHeight from 'react-animate-height';

//import "./QuestionBox.css";
import TitleTextImage from './TitleTextImage';
import Group from './Group';

interface QuestionBoxProps {
  data: any;
  update: () => void;
}

interface QuestionBoxState {
  selected: string | null;
}

export default class QuestionBox extends React.Component<
  QuestionBoxProps,
  QuestionBoxState
> {
  public constructor(props: QuestionBoxProps) {
    super(props);
    this.state = { selected: null };
  }

  public render() {
    let children, buttons;

    if (this.props.data.options) {
      const numAnswers = Object.keys(this.props.data.options).length;
      const widthPercent = 100 / numAnswers + '%';

      buttons = Object.keys(this.props.data.options).map(key => {
        const selected = key === this.state.selected;
        const value = this.props.data.options[key];
        return (
          <div
            className={
              selected ? 'questionBoxButton selected' : 'questionBoxButton'
            }
            style={{ width: widthPercent }}
            key={key}
            onClick={() => this.select(key)}
          >
            {key}
            <div className={selected ? 'arrow yes selected' : 'arrow yes'} />
          </div>
        );
      });

      children = Object.keys(this.props.data.options).map(key => {
        const selected = key === this.state.selected;
        const value = this.props.data.options[key];
        return (
          <AnimateHeight height={selected ? 'auto' : '0'} duration={1000}>
            <Group data={value} update={this.props.update} />
          </AnimateHeight>
        );
      });
    }

    return (
      <div className={'container'}>
        <div
          className={
            this.state.selected === 'Yes'
              ? 'box questionBoxContent selected'
              : 'box questionBoxContent'
          }
        >
          <TitleTextImage data={this.props.data} />
        </div>
        {buttons}
        {children}
      </div>
    );
  }

  public select(key: string): void {
    if (this.state.selected === key) {
      this.setState({ selected: null });
      this.props.data.selected = null;
    } else {
      this.setState({ selected: key });
      this.props.data.selected = key;
    }
    this.props.update();
  }
}
