import * as React from 'react';
import { Card, Checkbox, Grid } from 'semantic-ui-react';

import './Header.css';

interface TitleTextImageProps {
  data: any;
}

export default class TitleTextImage extends React.Component<TitleTextImageProps> {
  public render() {
    let cites = [];
    if (this.props.data.cite instanceof Array) {
      cites = this.props.data.cite;
    } else {
      cites = [this.props.data.cite];
    }

    const citeTags = cites.map((cite) => {
      return (<a className={'cite'} target="_blank" href={cite}>[citation]</a>);
    });

    return <div>
              <div className={'title'}>{this.props.data.title}</div>
              <div className={'text'}>{this.props.data.text}
                {citeTags}
              </div>
              {this.props.data.image && (
              <div
                className={'childImage'}
                style={{ backgroundImage: 'url(/images/' + this.props.data.image }}
              />)}
           </div>;
  }
}
