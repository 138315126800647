import * as React from 'react';
import { Card, Checkbox, Grid } from 'semantic-ui-react';
import AnimateHeight from 'react-animate-height';

//import "./QuestionBox.css";

import Group from './Group';

interface CheckBoxProps {
  data: any;
  update: () => void;
}

interface CheckBoxState {
  selected: string;
}

export default class CheckBox extends React.Component<
  CheckBoxProps,
  CheckBoxState
> {
  public constructor(props: CheckBoxProps) {
    super(props);
    this.state = { selected: null };
  }

  public render() {
    let child, button;

    if (this.props.data.options && this.props.data.options['Agree']) {
      const childData = this.props.data.options['Agree'];

      button = (
        <div
          className={
            this.state.selected ? 'checkBoxButton selected' : 'checkBoxButton'
          }
          onClick={event => this.toggle(event)}
        />
      );

      child = (
        <AnimateHeight
          height={this.state.selected ? 'auto' : '0'}
          duration={1000}
        >
          <Group data={childData} update={this.props.update} />
        </AnimateHeight>
      );
    }

    const image = this.props.data.image;

    return (
      <div className={'container'}>
        <div
          className={
            this.state.selected
              ? 'box questionBoxContent selected'
              : 'box questionBoxContent'
          }
          onClick={event => this.open(event)}
        >
          {button}
          <div className={'title checkBoxTitle'}>{this.props.data.title}</div>
          <div className={'text questionBoxText'}>{this.props.data.text}</div>
          <div
            className={'childImage'}
            style={{ backgroundImage: 'url(/images/' + image }}
          />
        </div>
        <div className={this.state.selected ? 'arrow selected yes' : 'arrow'} />
        {child}
      </div>
    );
  }

  public toggle(event): void {
    if (this.state.selected === "Agree") {
      this.setState({ selected: null });
      this.props.data.selected = null;
    } else {
      this.setState({ selected: "Agree" });
      this.props.data.selected = "Agree";
    }


    this.props.update();
    event.stopPropagation();
  }

  public open(event): void {
    this.setState({ selected: "Agree" });
    this.props.data.selected = "Agree";
    this.props.update();
  }
}
