import * as React from 'react';
import { Card, Checkbox, Grid } from 'semantic-ui-react';

import './ShareBox.css';

interface ShareBoxProps {}

interface ShareBoxState {}

export default class ShareBox extends React.Component<
  ShareBoxProps,
  ShareBoxState
> {
  public constructor(props: ShareBoxProps) {
    super(props);
  }

  public render() {
    //setTimeout(a2a.init_all, 0);
    return (
      <div className={'shareBox'}>
        <div className={"a2a_kit a2a_kit_size_32 a2a_default_style"}>
            <a className={"a2a_dd"} href={"https://www.addtoany.com/share"}></a>
            <a className={"a2a_button_facebook"}></a>
            <a className={"a2a_button_twitter"}></a>
            <a className={"a2a_button_google_plus"}></a>
        </div>
      </div>);
  }
}
