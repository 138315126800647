import * as React from 'react';

import Group from './Group';
import ShareBox from './ShareBox';
import Header from './Header';
import ConstructionBanner from './ConstructionBanner';

import './Main.css';

interface MainProps {
  treeData: {};
}

interface MainState {
  treeData: {};
}

export class Main extends React.Component<MainProps, MainState> {
  public constructor(props: MainProps) {
    super(props);
    this.state = {treeData: props.treeData};
  }

  public render() {

  let constructionBanner, debug;

 // constructionBanner = (<ConstructionBanner />);
/*
    const debug = (
      <textarea style={{width:"100%",height:"400px"}} onChange={(event) => this.set(event)}>
        {JSON.stringify(this.state.treeData, undefined, 2)}
      </textarea>
    );
*/
    return (
      <div>
        {constructionBanner}
        <div className={'main'}>
          <Header />
          <Group data={this.state.treeData} update={() => this.update()}/>
          <ShareBox />
        </div>
        {debug}
      </div>
    );
  }

  private update() {
   //console.log("UPDATE", this.state.treeData);
   //console.log(this.encode(this.state.treeData));
  }
/*
  interface Data {
    options: {},
    selected: string,
  }*/

  private encode(data): string {
    if (data.selected && data.options) {
      const keys = data.options.keys().sort();
      const index = keys.find(data.selected);
      if (index >= 0) {
        return index + this.encode(data.options[data.selected]);
      }
      return '';
    }
    return '';
  }

  private set(event) {
    this.setState({treeData: JSON.parse(event.target.value)});
  }
}
